import React from 'react';
import PropTypes from 'prop-types';

const TodoItem = (props) => {
  const {
    title,
    handleDelete,
    handleEdit,
  } = props;
  return (
    <li className="list-group-item text-capitalize d-flex justify-content-between my-2">
      <h6>{title}</h6>
      <div className="todo-icons">
        <span className="mx-2 text-success" onClick={handleEdit} role="presentation">
          <i className="fas fa-pen" />
        </span>
        <span className="mx-2 text-danger" onClick={handleDelete} role="presentation">
          <i className="fas fa-trash" />
        </span>
      </div>
    </li>
  );
};

TodoItem.propTypes = {
  title: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};
export default TodoItem;
