import React from 'react';
import PropTypes from 'prop-types';
import TodoItem from './TodoItem';

const TodoList = (props) => {
  const {
    items,
    clearList,
    handleDelete,
    handleEdit,
  } = props;
  return (
    <ul className="list-group my-5">
      <h3 className="text-capitalize text-center">todo list</h3>
      {
            items.map((item) => (
              <TodoItem
                key={item.id}
                title={item.title}
                handleDelete={() => handleDelete(item.id)}
                handleEdit={() => handleEdit(item.id)}
              />
            ))
        }
      <button type="button" className=" btn btn-danger btn-block text-capitalize mt-5" onClick={clearList}>clear list</button>
    </ul>
  );
};

TodoList.propTypes = {
  items: PropTypes.string.isRequired,
  clearList: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};
export default TodoList;
